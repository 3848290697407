import { render, staticRenderFns } from "./_uid.vue?vue&type=template&id=400734be&scoped=true&"
import script from "./_uid.vue?vue&type=script&lang=js&"
export * from "./_uid.vue?vue&type=script&lang=js&"
import style0 from "./_uid.vue?vue&type=style&index=0&id=400734be&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "400734be",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modules: require('/vercel/path0/components/Modules.vue').default,Footer: require('/vercel/path0/components/Footer.vue').default})
