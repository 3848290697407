//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Animation from '@/components/Animation'
export default {
  components: {
    Animation,
  },
}
