//
//
//
//
//
//
//
//

import { pageQuery } from '~/graphql/queries/page'
// import Intro from '@/components/Intro'
import Modules from '@/components/Modules'
import Footer from '@/components/Footer'
export default {
  components: {
    Modules,
    Footer,
    // Intro,
  },
  async asyncData({ app, route, payload }) {
    const query = {
      query: pageQuery,
      variables: {
        slug: route.params.uid || route.name,
      },
      prefetch: true,
    }

    // For previews
    if (route.query.preview) {
      query.errorPolicy = 'all'
      query.fetchPolicy = 'no-cache'
    }

    if (payload && payload.page) {
      const newPage = payload.page
      newPage.id = payload.page.id
      return { page: newPage }
    } else {
      const page = await app.apolloProvider.defaultClient.query(query)
      return { page: page.data.pages.data[0].attributes }
    }
  },
  head() {
    return {
      title:
        this.$route.path === '/'
          ? 'Thank You Studios'
          : (this.page.title || this.page.attributes.title) +
            ' — Thank You Studios',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content:
            this.$route.path === '/'
              ? 'Thank You Studios'
              : (this.page.title || this.page.attributes.title) +
                ' — Thank You Studios',
        },
        {
          hid: 'description',
          name: 'description',
          content: 'We make films, build brands, and venture into the unknown.',
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: 'We make films, build brands, and venture into the unknown.',
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: 'https://www.thankyoustudio.com/some.jpg',
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: '1200',
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: '1200',
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: '630',
        },
      ],
    }
  },

  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0)
      this.$colorMode.preference = 'dark'
    }, 10)
  },
}
